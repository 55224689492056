import Hero from "@components/home/Hero";
import Features from "@components/home/Features";
import Footer from "@components/home/Footer";
import VideoDemo from "@components/home/VideoDemo";
import Pricing from "@components/home/Pricing";
import "@src/i18next";

// import { lazy } from "react";
// import SuspenseWrapper from "@components/common/Suspsence";
// const AccountApp = lazy(() => import("@pages/account"));

{
  /* <Routes>
  <Route
    path="/account"
    element={
      <SuspenseWrapper>
        <AccountApp />
      </SuspenseWrapper>
    }
  />
</Routes> */
}

const Home = () => {
  return (
    <div>
      <main className="pt-[100px]">
        <Pricing />
        <Hero />
        <Features />
        <VideoDemo />

        {/* <Guidance />
        <SkyCloudPricing /> */}
      </main>

      <Footer />
    </div>
  );
};

export default Home;
