import { toMoney } from "@src/utils";
import { useTranslation } from "react-i18next";
// import { Image } from "@components/common";
import { type machinePrice } from "@utils/api/resource";

const kbToGb = 1024 * 1024;

export default function GpuCard({ gpuType, price }: machinePrice) {
  const { t } = useTranslation();

  return (
    <div className="box-border border border-gray-100 p-8 w-full bg-white rounded-md shadow-lg flex flex-col justify-between animate-fade animate-duration-500">
      {/* <Image
        className="mx-auto h-20"
        src={require(`/${imgName}`)}
        alt="Colossal Cloud"
      /> */}
      <div>
        <div className="font-semibold text-lg mb-3 whitespace-nowrap">
          {`${gpuType.gpuName} / ${(gpuType.gpuMemory / kbToGb).toFixed()} GB`}
        </div>
        <div className="whitespace-nowrap">
          <span className="text-sm">
            <span className="text-red-500 mr-1">
              ¥
              <span className="text-2xl font-medium">
                {toMoney(price.perHour)}
              </span>
            </span>
            {t("resource.price.hour")}
          </span>
          {price.perHourOrigin > price.perHour && (
            <>
              <span className="text-sm text-gray-500 ml-2 line-through">
                {`¥${toMoney(price.perHourOrigin)}${t("resource.price.hour")}`}
              </span>
              <div className="bg-yellow-200 text-yellow-800 inline-block text-xs px-1 py-0.5 rounded ml-2">
                {t("resource.price.discount")}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
