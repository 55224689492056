import { useTranslation } from "react-i18next";

export default function VideoDemo() {
  const { t } = useTranslation();
  return (
    <div className="overflow-hidden">
      <div className="mx-auto max-w-4xl text-center mt-12">
        <h2 className="font-bold tracking-tight text-gray-900 text-4xl">
          {t("home.video")}
        </h2>
      </div>
      <div className="mt-10 pb-12 sm:pb-16">
        <video
          className="mx-auto"
          controls
          width="1200"
          height="675"
          poster={`/video/demo.png`}
        >
          <source src={`/video/demo.mp4`} type="video/mp4" />
          Your browser does not support the video player, please upgrade your
          browser or open it in another browser.
        </video>
      </div>
    </div>
  );
}
