import { HiMiniCheckCircle } from "react-icons/hi2";

export interface FeatureCardProps {
  title: string;
  features: string[];
  button: React.ReactNode;
}

export const btnClass =
  "flex w-full justify-center mx-auto h-12 rounded-3xl items-center mt-8 bg-blue-500 text-white group-hover:bg-purple-400 transition-colors duration-300 hover:!bg-purple-500 cursor-pointer";

export default function Card({ title, features, button }: FeatureCardProps) {
  return (
    <div className="rounded-3xl px-8 py-6 group hover:bg-indigo-900 hover:text-white transition-colors duration-300 flex flex-col justify-between hover:shadow">
      <div>
        <div className="text-2xl mb-8">{title}</div>
        {features.map((feature, index) => (
          <div key={index} className="flex items-start mb-4">
            <HiMiniCheckCircle className="inline-block h-5 w-5 mt-0.5 mr-1 text-blue-500 group-hover:text-blue-400 transition-colors duration-300" />
            <span className="flex-1">{feature}</span>
          </div>
        ))}
      </div>

      {button}
    </div>
  );
}
